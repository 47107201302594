import React, { useEffect, useState } from "react";
import Newlogin from "./views/NewLogin";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(() => sessionStorage.getItem("user") !== null);
  const [lastInteractionTime, setLastInteractionTime] = useState(Date.now());

  useEffect(() => {
    const events = ["mousemove", "keydown", "click", "scroll", "touchstart"];
    const updateLastInteraction = () => setLastInteractionTime(Date.now());

    events.forEach((event) => window.addEventListener(event, updateLastInteraction));

    return () => {
      events.forEach((event) => window.removeEventListener(event, updateLastInteraction));
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (Date.now() - lastInteractionTime > 50 * 60 * 1000) {
        sessionStorage.removeItem("user");
        setIsLoggedIn(false);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [lastInteractionTime]);

  return (
    <div className="App">
      {isLoggedIn ? <Newlogin /> : <Newlogin />}
    </div>
  );
}

export default App;
