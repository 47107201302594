const blockedCards = [
  "	1098832",
  "400329",
  "403788",
  "411634",
  "570965",
  "575588",
  "591401",
  "596634",
  "609277",
  "611103",
  "611727",
  "612301",
  "637589",
  "639039",
  "640066",
  "640776",
  "647118",
  "647306",
  "647501",
  "647632",
  "647796",
  "650012",
  "653079",
  "655182",
  "655404",
  "655665",
  "658266",
  "658647",
  "659707",
  "660392",
  "660688",
  "662665",
  "662921",
  "693300",
  "697458",
  "699217",
  "700524",
  "701289",
  "703032",
  "708020",
  "713557",
  "720394",
  "723684",
  "730880",
  "765711",
  "765717",
  "765842",
  "766345",
  "766419",
  "767643",
  "767645",
  "768131",
  "769363",
  "772952",
  "773633",
  "775046",
  "775277",
  "775422",
  "775567",
  "776737",
  "777621",
  "777670",
];

export default blockedCards;
