import React, { useState, useEffect } from "react";
import TamblExamTimetable from "./TamblExamTimetable";
import generalQuery from "../generalQuery";
import ExamScheduleTable from "./ExamScheduleTable ";
const Exams = () => {
  const [classes, setClasses] = useState([]);
  const [datesOptions, setDatesOptions] = useState([]);
  const [classroom, setClassroom] = useState("");
  const [sub, setSub] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [dates, setDates] = useState([]);
  const [teachersub, setTeachersub] = useState(sessionStorage.getItem("sub"));
  const [data, setData] = useState([]);
  const [showOverview, setShowOverview] = useState(false);
  const sections = [
    "8A",
    "8B_1",
    "8B_2",
    "8C",
    "8D",
    "8E",
    "8F_1",
    "8F_2",
    "8G",
    "8H",
    "8I_1",
    "8I_2",
    "8J_1",
    "8J_2",
  ];
  //  [
  //   "9A",
  //   "9B",
  //   "9C",
  //   "9D",
  //   "9E",
  //   "9F",
  //   "9G",
  //   "9H",
  //   "9I",
  //   "9J",
  //   "9K",
  //   "10A",
  //   "10B",
  //   "10C",
  //   "10D",
  //   "10E",
  //   "10F",
  //   "10G",
  //   "10H",
  //   "11A",
  //   "11B",
  //   "11C",
  //   "11D",
  //   "11E",
  //   "11F",
  // ];

  const getClassesForExams = async (x) => {
    try {
      const response = await fetch(
        "https://mut.herokuapp.com/getClassesForExams",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const resp = await response.json();
      const arr = [];
      resp.forEach((e) => {
        if (arr.indexOf(e.class) === -1) {
          arr.push(e.class);
        }
      });
      setClasses(arr);
    } catch (error) {
      console.log(error);
    }
  };
  const getExams = async (x) => {
    try {
      const body = { classroom: x };
      const response = await fetch("https://mut.herokuapp.com/getExams", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const resp = await response.json();
      const arr = [];
      resp.forEach((e) => {
        if (e.arabic !== "null") {
          arr.push(e.arabic);
        }
        if (e.english !== "null") {
          arr.push(e.english);
        }
        if (e.islamic !== "null") {
          arr.push(e.islamic);
        }
        if (e.math !== "null") {
          arr.push(e.math);
        }
        if (e.science !== "null") {
          arr.push(e.science);
        }
        if (e.socialst !== "null") {
          arr.push(e.socialst);
        }
        if (e.History !== "null") {
          arr.push(e.history);
        }
        if (e.Sports !== "null") {
          arr.push(e.sports);
        }
        if (e.comp !== "null") {
          arr.push(e.comp);
        }
      });
      setDatesOptions(arr);
      // setExams(resp);
    } catch (error) {
      console.log(error);
    }
  };
  const updateExamDate = async (x) => {
    try {
      const body = { classroom, sub, exDate: x };
      const response = await fetch("https://mut.herokuapp.com/updateExamDate", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const resp = await response.json();
      getExams(classroom);
    } catch (error) {
      console.log(error);
    }
  };
  const getDate = (r, dt) => {
    if (r[dt]) {
      // return new Date("2023-" + r[dt].slice(-1) + "-" + r[dt].slice(0, 2));
      return r[dt];
    } else {
      return "";
    }
  };
  useEffect(() => {
    getClassesForExams();
    if (teachersub === "Math" || teachersub === "Numeracy") {
      setDates([
        "17/02/2025",
        "18/02/2025",
        "19/02/2025",
        "20/02/2025",
        "21/02/2025",
        "23/02/2025",
        "24/02/2025",
        "25/02/2025",
        "26/02/2025",
      ]);
    } else if (teachersub === "English") {
      setDates([
        "17/02/2025",
        "18/02/2025",
        "19/02/2025",
        "20/02/2025",
        "21/02/2025",
        "23/02/2025",
        "24/02/2025",
        "25/02/2025",
        "26/02/2025",
      ]);
    } else if (teachersub === "Science") {
      setDates([
        "17/02/2025",
        "18/02/2025",
        "19/02/2025",
        "20/02/2025",
        "21/02/2025",
        "23/02/2025",
        "24/02/2025",
        "25/02/2025",
        "26/02/2025",
      ]);
    } else if (teachersub === "Arabic") {
      setDates([
        "17/02/2025",
        "18/02/2025",
        "19/02/2025",
        "20/02/2025",
        "21/02/2025",
        "23/02/2025",
        "24/02/2025",
        "25/02/2025",
        "26/02/2025",
      ]);
    } else if (
      teachersub === "Gov" ||
      teachersub === "Comp" ||
      teachersub === "PE" ||
      teachersub === "Music" ||
      teachersub === "Drama" ||
      teachersub === "Art"
    ) {
      setDates([
        // "4/12/2024",
        // "5/12/2024",
        // "6/12/2024",
        // "9/12/2024",
        // "10/12/2024",
        // "11/12/2024",
        // "12/12/2024",
        // "13/12/2024",
      ]);
    } else if (teachersub === "Social" || teachersub === "Islamic") {
      setDates([
        "17/02/2025",
        "18/02/2025",
        "19/02/2025",
        "20/02/2025",
        "21/02/2025",
        "23/02/2025",
        "24/02/2025",
        "25/02/2025",
        "26/02/2025",
      ]);
    }
    // setDates([]);
    // else {
    //   setDates([]);
    // }
  }, []);

  return (
    <div>
      <h2>Key Assessment 3 (KA3)- Grade 8 </h2>
      {/* <h2>Key Assessment 3 (KA3)- Grades 9,10 and 11 </h2> */}
      <h2>2024-2025 </h2>
      <div className="container">
        <select
          className="select"
          onChange={(e) => {
            setShowOverview(false);
            getExams(e.target.value);
            setClassroom(e.target.value);
            generalQuery(
              `select * from exams where class='${e.target.value}'`
            ).then((results) => {
              setData(
                [
                  ["Arabic", getDate(results[0], "arabic")],
                  ["English", getDate(results[0], "english")],
                  ["Math", getDate(results[0], "math")],
                  ["Science", getDate(results[0], "science")],
                  ["Islamic St", getDate(results[0], "islamic")],
                  ["Social St", getDate(results[0], "socialst")],
                  ["Technology", getDate(results[0], "ict")],
                  ["History", getDate(results[0], "history")],
                  ["Drama", getDate(results[0], "drama")],
                  ["Sports", getDate(results[0], "sports")],
                  ["Music", getDate(results[0], "music")],
                  ["Visual Art", getDate(results[0], "arts")],
                  ["Robotics", getDate(results[0], "robotics")],
                ]
                  .filter((a) => a[1] !== "")
                  .sort((a, b) => (a[1] > b[1] ? 1 : -1))
              );
            });
          }}
        >
          <option value="">Class.....الشعبة</option>
          {sections.map((c) => {
            return <option key={c}>{c}</option>;
          })}
          {/* {classes.map((c) => {
            return <option key={c}>{c}</option>;
          })} */}
        </select>
        <select
          className="select"
          onChange={(e) => {
            setShowOverview(false);
            setSub(e.target.value);
          }}
        >
          <option value="">Select Your Subject المادة</option>
          {teachersub === "Arabic" ? (
            <option value="arabic">Arabic</option>
          ) : teachersub === "English" ? (
            <option value="english">English</option>
          ) : teachersub === "Math" ? (
            <option value="math">Math</option>
          ) : teachersub === "Science" ? (
            <option value="science">Science</option>
          ) : teachersub === "Comp" ? (
            <>
              <option value="ict">Computer Sc</option>
              <option value="robotics">Robotics</option>
            </>
          ) : teachersub === "Gov" ? (
            <option value="History">History</option>
          ) : teachersub === "Drama" ? (
            <>
              <option value="drama">Drama</option>
              <option value="arts">Visual Arts</option>
            </>
          ) : teachersub === "Music" ? (
            <>
              <option value="music">Music</option>
            </>
          ) : teachersub === "PE" ? (
            <option value="Sports">Sports</option>
          ) : (
            <>
              <option value="socialst">Social St</option>
              <option value="islamic">اسلامية</option>
            </>
          )}
        </select>
        <button
          className="select"
          onClick={() => {
            setShowOverview(true);
          }}
        >
          Overview
        </button>
        {sub !== "" && !showOverview && (
          <div style={{ marginTop: "50px" }}>
            {dates.map((d) => {
              if (datesOptions.indexOf(d) === -1) {
                return (
                  <button
                    style={{
                      padding: "12px",
                      backgroundColor: "greenyellow",
                      margin: "0 5px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    // onClick={(c) => {
                    //   updateExamDate(d);

                    //   generalQuery(
                    //     `select * from exams where class='${classroom}'`
                    //   ).then((results) => {
                    //     // setInterval(() => {

                    //     // }, 2000);
                    //     setData(
                    //       [
                    //         ["Arabic", getDate(results[0], "arabic")],
                    //         ["English", getDate(results[0], "english")],
                    //         ["Math", getDate(results[0], "math")],
                    //         ["Science", getDate(results[0], "science")],
                    //         ["Islamic St", getDate(results[0], "islamic")],
                    //         ["Social St", getDate(results[0], "socialst")],
                    //         ["Technology", getDate(results[0], "ict")],
                    //         ["History", getDate(results[0], "history")],
                    //         ["Drama", getDate(results[0], "drama")],
                    //         ["Sports", getDate(results[0], "sports")],
                    //         ["Music", getDate(results[0], "music")],
                    //         ["Visual Art", getDate(results[0], "arts")],
                    //         ["Robotics", getDate(results[0], "robotics")],
                    //       ]
                    //         .filter((a) => a[1] !== "")
                    //         .sort((a, b) => (a[1] > b[1] ? 1 : -1))
                    //     );
                    //   });
                    // }}
                  >
                    {d}
                  </button>
                );
              } else {
                return (
                  <span
                    style={{
                      padding: "12px",
                      backgroundColor: "pink",
                      margin: "0 5px",
                      fontWeight: "bold",
                      color: "gray",
                    }}
                  >
                    {d}
                  </span>
                );
              }
            })}
          </div>
        )}
      </div>
      {data.length > 0 && !showOverview && <TamblExamTimetable data={data} />}
      {showOverview && <ExamScheduleTable />}
    </div>
  );
};

export default Exams;
