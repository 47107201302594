import React from "react";
import jsPDF from "jspdf";
import { font } from "../font";
import { Button } from "@material-ui/core";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";

const SectionsTimetablePrinting = ({ sectionObjects, classes }) => {
  function generateColorCode(text) {
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
      hash = text.charCodeAt(i) + ((hash << 5) - hash);
    }
    const lighterValue = 60;
    let r = (hash & 0xff0000) >> 16;
    let g = (hash & 0x00ff00) >> 8;
    let b = hash & 0x0000ff;
    r = Math.min(255, r + lighterValue);
    g = Math.min(255, g + lighterValue);
    b = Math.min(255, b + lighterValue);
    return { r, g, b };
  }
  const generatePDF = () => {
    var doc = new jsPDF("l");
    doc.addFileToVFS("IBMPlexSansArabic-Medium-normal.ttf", font);
    doc.addFont(
      "IBMPlexSansArabic-Medium-normal.ttf",
      "IBMPlexSansArabic-Medium",
      "normal"
    );
    doc.setFont("IBMPlexSansArabic-Medium");

    const pageContent = (p, section) => {
      let sectionData = sectionObjects.filter((s) => s.section === section)[0];

      const periods = () => {
        return section.match(/\d+/g).map(Number)[0] === 9 ||
          section.match(/\d+/g).map(Number)[0] === 10
          ? ["p1", "p2", "p3", "Break", "p4", "p5", "Break", "p6"]
          : ["p1", "p2", "Break", "p3", "p4", "p5", "Break", "p6"];
      };

      const Frperiods = () => {
        return section.match(/\d+/g).map(Number)[0] === 9 ||
          section.match(/\d+/g).map(Number)[0] === 10
          ? ["p1", "p2", "Break", "p3", "p4"]
          : ["p1", "Break", "p2", "p3", "p4"];
      };

      const timing = () => {
        return section.match(/\d+/g).map(Number)[0] === 11 ||
          section.match(/\d+/g).map(Number)[0] === 8
          ? [
              "8:00-9:00",
              "9:00-10:00",
              "10:00-10:20",
              "10:20-11:20",
              "11:20-12:20",
              "12:20-13:20",
              "13:20-13:30",
              "13:30-14:30",
            ]
          : [
              "8:00-9:00",
              "09:00:-10:00",
              "10:00:-11:00",
              "11:00-11:20",
              "11:20-12:20",
              "12:20-13:20",
              "13:20-13:30",
              "13:30-14:30",
            ];
      };

      const Frtiming = () => {
        return section.match(/\d+/g).map(Number)[0] === 9 ||
          section.match(/\d+/g).map(Number)[0] === 10
          ? [
              "8:00-8:45",
              "8:45-9:30",
              "9:30-10:00",
              "10:00-10:45",
              "10:45-11:30",
            ]
          : [
              "8:00-8:45",
              "8:45-9:15",
              "9:15-10:00",
              "10:00-10:45",
              "10:45-11:30",
            ];
      };

      doc.setFontSize(8);
      doc.text("mutanabi.ae", 4, 4);
      doc.text(new Date().toString().slice(0, 15), 267, 4);
      let cellWidth = 0;
      const cellHeight = 20;
      const padding = 5;
      let startX = 10;
      let startY = 30;
      let rowNum = 0;
      let colNum = 0;
      doc.setLineWidth(0.4);
      doc.setDrawColor("black");

      doc.setFontSize(20);
      doc.text(section, 140, 20);

      if (section.slice(0, 1) === "7" || section.slice(0, 1) === "6") {
        rowNum = 7;
        colNum = 8;
        cellWidth = 35;
      } else {
        rowNum = 7;
        colNum = 9;
        cellWidth = 29;
      }

      for (let i = 0; i < rowNum; i++) {
        for (let j = 0; j < colNum; j++) {
          doc.rect(startX, startY, cellWidth, cellHeight);
          startX += cellWidth;
        }
        startX = 10;
        startY += cellHeight;
      }
      let days = ["Monday", "Tuesday", "Wednesday", "Thursday", "", "Friday"];
      let daysInit = ["m", "t", "w", "th", "", "f"];

      days.forEach((d, indexD) => {
        doc.setFontSize(10);
        doc.text(
          d,

          startX + padding,
          60 + 20 * indexD
        );
        let periodsArr = [];
        if (d !== "Friday") {
          periodsArr = periods();
        } else {
          periodsArr = Frperiods();
        }

        periodsArr.forEach((p, indexP) => {
          if (p !== "Break" && d !== ""&&sectionData[daysInit[indexD] + "Sub"][p] !== "null") {
            let fillColor = generateColorCode(
              sectionData[daysInit[indexD] + "Sub"][p] + "kjkjjkhghgfhgfd"
            );
            doc.setFillColor(fillColor.r, fillColor.g, fillColor.b);
            doc.rect(
              10 + cellWidth * (indexP + 1),
              30 + cellHeight * (indexD + 1),
              cellWidth,
              cellHeight,
              "F"
            );

            let horizShiftFactor = 0;
            let horizShiftFactorText = 0;
            if (section.slice(0, 1) === "7" || section.slice(0, 1) === "6") {
              horizShiftFactor = 7;
              horizShiftFactorText = 1;
            } else {
              horizShiftFactor = 5.7;
            }
            if (sectionData[daysInit[indexD] + "Sub"][p] !== "null"&&sectionData[daysInit[indexD] + "Sub"][p] !== null) {
              doc.text(
                sectionData[daysInit[indexD] + "Sub"][p],
                56 +
                  horizShiftFactorText * 10 -
                  horizShiftFactor -
                  sectionData[daysInit[indexD] + "Sub"][p].length / 2 +
                  padding * (indexP * horizShiftFactor),
                55 + 20 * indexD
              );

              let teacherName = (
                sectionData[daysInit[indexD] + "Teacher"][p]
                  .split(" ")
                  .slice(0, 1)
                  .join(" ") +
                " " +
                sectionData[daysInit[indexD] + "Teacher"][p]
                  .split(" ")
                  .slice(1, 2)
                  .join(" ")
                  .slice(0, 6)
              ).slice(0, 11);

              doc.text(
                teacherName,
                60 +
                  horizShiftFactorText * 10 -
                  horizShiftFactor -
                  teacherName.length +
                  padding * (indexP * horizShiftFactor),
                60 + 20 * indexD
              );

              doc.text(
                sectionData[daysInit[indexD] + "Rooms"][p],
                50 +
                  horizShiftFactorText * 10 -
                  sectionData[daysInit[indexD] + "Rooms"][p].length / 2 +
                  padding * (indexP * horizShiftFactor),
                65 + 20 * indexD
              );
            }
          }
        });
      });

      periods().forEach((p, indexP) => {
        if (section.slice(0, 1) === "7" || section.slice(0, 1) === "6") {
          doc.text(p, 60 + padding * (indexP * 7), 35);
          doc.text(timing()[indexP], 50 + padding * (indexP * 7), 45);
        } else {
          doc.text(p, 50 + padding * (indexP * 5.7), 35);
          doc.text(timing()[indexP], 45 + padding * (indexP * 5.7), 45);
        }
      });

      Frperiods().forEach((p, indexP) => {
        if (section.slice(0, 1) === "7" || section.slice(0, 1) === "6") {
          doc.text(p, 60 + padding * (indexP * 7), 135);
          doc.text(Frtiming()[indexP], 50 + padding * (indexP * 7), 145);
        } else {
          doc.text(p, 50 + padding * (indexP * 5.7), 135);
          doc.text(Frtiming()[indexP], 45 + padding * (indexP * 5.7), 145);
        }
      });
    };

    pageContent(1, classes[0]);
    for (var n = 1; n < 49; ++n) {
      doc.addPage();
      pageContent(n, classes[n]);
    }

    doc.save("Class Timetable");
  };

  return (
    <div>
      <Button
        style={{
          backgroundColor: "#2196f3",
          color: "#ffffff",
        }}
        startIcon={<LocalPrintshopIcon />}
        onClick={() => {
          generatePDF();
        }}
      >
        Print
      </Button>
    </div>
  );
};

export default SectionsTimetablePrinting;
